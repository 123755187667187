import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: "https://api.1minex.com/api/",
  baseURL: "https://api.1minex.simagar.com/api/",
  // baseURL: "https://apitest.1minex.com/api/",
  // timeout: 10000,
  // headers: {'X-Custom-Header': 'foobar'}
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
