export default [
  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: "/apps/chats",
    name: "apps-chats",
    component: () => import("@/views/apps/chat/Chats.vue"),
    title: "چت ها",
    meta: {
      resource: "Support",
      action: "Support",
      pageTitle: "چت ها",
      // breadcrumb: [
      //   {
      //     text: 'لیست',
      //     active: true
      //   },
      // ],
    },
  },
  {
    path: "/apps/chats/:id",
    name: "apps-chats-id",
    resource: "Support",
    action: "Support",
    component: () => import("@/views/apps/chat/Chat.vue"),
    meta: {
      // contentRenderer: 'sidebar-left',
      contentClass: "chat-application",
    },
  },

  //  Ticket
  {
    path: "/apps/allTickets",
    name: "apps-allTickets",
    component: () => import("@/views/apps/Ticket/AllTickets.vue"),
  },
  {
    path: "/apps/ticketDetail/:id",
    name: "apps-ticketDetail-id",
    component: () => import("@/views/apps/Ticket/Detail/TicketDetail.vue"),
  },

  //  User
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },
  {
    path: "/apps/users/tatum",
    name: "apps-users-tatum",
    component: () => import("@/views/apps/user/Tatum.vue"),
  },
  {
    path: "/apps/fake-names/list",
    name: "apps-fake-names-list",
    component: () => import("@/views/apps/fake-names/FakeNameList.vue"),
  },

  // FakeUsers
  {
    path: "/apps/fake-users/reports",
    name: "apps-fake-users-reports",
    component: () => import("@/views/apps/fake-users/Reports.vue"),
  },

  //  Financial
  {
    path: "/apps/financial/payments/list",
    name: "apps-financial-payments-list",
    component: () => import("@/views/apps/financial/payments/PaymentsList.vue"),
    meta: {
      resource: "Accountent",
      action: "Accountent",
    },
  },
  {
    path: "/apps/financial/payments/info/:id",
    name: "apps-financial-payments-info",
    component: () => import("@/views/apps/financial/payments/PaymentInfo.vue"),
    meta: {
      resource: "Accountent",
      action: "Accountent",
    },
  },
  {
    path: "/apps/orders/list",
    name: "apps-orders-list",
    component: () => import("@/views/apps/orders/OrdersList.vue"),
    meta: {
      resource: "Accountent",
      action: "Accountent",
    },
  },
  {
    path: "/apps/orders/info/:id",
    name: "apps-orders-info",
    component: () => import("@/views/apps/orders/OrderInfo.vue"),
    meta: {
      resource: "Accountent",
      action: "Accountent",
    },
  },

  //  Candles
  {
    path: "/apps/candles/list",
    name: "apps-candles-list",
    component: () => import("@/views/apps/candles/CandlesList.vue"),
  },

  // Candle Details
  {
    path: "/apps-candles/:id",
    name: "apps-candle-details",
    component: () => import("@/views/apps/candles/CandleDetails.vue"),
  },

  //  Trades
  {
    path: "/apps/trades/list",
    name: "apps-trades-list",
    component: () => import("@/views/apps/trades/TradesList.vue"),
  },

  //  Wallets
  {
    path: "/apps/wallets/list",
    name: "apps-wallets-list",
    component: () => import("@/views/apps/wallets/WalletsList.vue"),
  },

  // Referral Links
  {
    path: "/apps/referral/links/list",
    name: "apps-referral-links-list",
    component: () => import("@/views/apps/Referral/ReferralLinkList.vue"),
  },

  // Referral Users
  {
    path: "/apps/referral/users/list",
    name: "apps-referral-users-list",
    component: () => import("@/views/apps/Referral/UsersList.vue"),
  },

  // Referral Requests List
  {
    path: "/apps/referral/cash-request",
    name: "apps-referral-cash-request",
    component: () => import("@/views/apps/Referral/CashRequestList.vue"),
  },

  // Referral Commission Requests List
  {
    path: "/apps/referral/commission-cash-request",
    name: "apps-referral-commission-cash-request",
    component: () =>
      import("@/views/apps/Referral/ComissionCashoutRequest.vue"),
  },

  // Transactions
  {
    path: "/apps/transactions/list",
    name: "apps-transactions-list",
    component: () => import("@/views/apps/transactions/TransactionList.vue"),
  },
  // Blockchains
  {
    path: "/apps/blockchains/list",
    name: "apps-blockchains-list",
    component: () => import("@/views/apps/blockchains/BlockchainsList.vue"),
  },

  // StaticPages
  {
    path: "/apps/static-pages/about-us",
    name: "apps-static-pages-about-us",
    component: () => import("@/views/apps/static-pages/AboutUs.vue"),
  },
  {
    path: "/apps/static-pages/terms",
    name: "apps-static-pages-terms",
    component: () => import("@/views/apps/static-pages/Terms.vue"),
  },
  {
    path: "/apps/static-pages/rule",
    name: "apps-static-pages-rule",
    component: () => import("@/views/apps/static-pages/Rule.vue"),
  },

  // Financials
  {
    path: "/apps/financials",
    name: "apps-financials",
    component: () => import("@/views/apps/financial/Financial.vue"),
  },

  // DynamicPages
  {
    path: "/apps/dynamic-pages/list",
    name: "apps-dynamic-pages-list",
    component: () => import("@/views/apps/dynamic-pages/DynamicPagesList.vue"),
  },
  {
    path: "/apps/dynamic-pages/create",
    name: "apps-dynamic-pages-create",
    component: () => import("@/views/apps/dynamic-pages/DynamicPageCreate.vue"),
  },
  {
    path: "/apps/dynamic-pages/edit/:id",
    name: "apps-dynamic-pages-edit-id",
    component: () => import("@/views/apps/dynamic-pages/DynamicPageEdit.vue"),
  },
  {
    path: "/apps/popups/list",
    name: "pages-popup-list",
    component: () => import("@/views/apps/Popup/PopupList.vue"),
  },
  {
    path: "/apps/bonus/list",
    name: "pages-bonus-list",
    component: () => import("@/views/apps/Bonus/BonusList.vue"),
  },

  // User Level
  {
    path: "/apps/user-levels/list",
    name: "apps-user-levels-list",
    component: () => import("@/views/apps/UserLevels/UserLevelList.vue"),
  },
];
